import type {
	ClientConfig,
	CreateClientConfig,
	User,
	UploadRequestOptions,
	OperationMetadata,
	OperationsDefinition,
	OperationRequestOptions,
	SubscriptionRequestOptions,
	SubscriptionEventHandler,
	FetchUserRequestOptions,
	UploadValidationOptions,
	QueryRequestOptions,
	MutationRequestOptions,
	ClientOperationErrors,
	ExtractProfileName,
	ExtractMeta,
	GraphQLError,
} from "@wundergraph/sdk/client";
import { Client } from "@wundergraph/sdk/client";
import type { OperationErrors } from "./ts-operation-errors";

import type { PublicCustomClaims } from "./claims";
import type {
	KrogerCustomGetKrogerAccessTokenResponse,
	KrogerCustomGetKrogerAccessTokenResponseData,
	KrogerCustomGetKrogerProductsResponse,
	KrogerCustomGetKrogerProductsInput,
	KrogerCustomGetKrogerProductsResponseData,
	StrapiCustomCreateOrderNotificationStatusResponse,
	StrapiCustomCreateOrderNotificationStatusInput,
	StrapiCustomCreateOrderNotificationStatusResponseData,
	StrapiCustomCreatePostResponse,
	StrapiCustomCreatePostInput,
	StrapiCustomCreatePostResponseData,
	StrapiCustomCreateProductResponse,
	StrapiCustomCreateProductInput,
	StrapiCustomCreateProductResponseData,
	StrapiCustomCreateUserProfileResponse,
	StrapiCustomCreateUserProfileInput,
	StrapiCustomCreateUserProfileResponseData,
	StrapiCustomDeleteFileResponse,
	StrapiCustomDeleteFileInput,
	StrapiCustomDeleteFileResponseData,
	StrapiCustomGetOrderNotificationStatusResponse,
	StrapiCustomGetOrderNotificationStatusInput,
	StrapiCustomGetOrderNotificationStatusResponseData,
	StrapiCustomGetPostsResponse,
	StrapiCustomGetPostsResponseData,
	StrapiCustomGetProductsResponse,
	StrapiCustomGetProductsInput,
	StrapiCustomGetProductsResponseData,
	StrapiCustomGetUserProfilesResponse,
	StrapiCustomGetUserProfilesInput,
	StrapiCustomGetUserProfilesResponseData,
	StrapiCustomGetVendorAdminsResponse,
	StrapiCustomGetVendorAdminsInput,
	StrapiCustomGetVendorAdminsResponseData,
	StrapiCustomGetVendorUserMapResponse,
	StrapiCustomGetVendorUserMapInput,
	StrapiCustomGetVendorUserMapResponseData,
	StrapiCustomGetVendorsResponse,
	StrapiCustomGetVendorsInput,
	StrapiCustomGetVendorsResponseData,
	StrapiCustomSingleImageUploadResponse,
	StrapiCustomSingleImageUploadInput,
	StrapiCustomSingleImageUploadResponseData,
	StrapiCustomUpdateOrderNotificationStatusResponse,
	StrapiCustomUpdateOrderNotificationStatusInput,
	StrapiCustomUpdateOrderNotificationStatusResponseData,
	StrapiCustomUpdateUserProfileResponse,
	StrapiCustomUpdateUserProfileInput,
	StrapiCustomUpdateUserProfileResponseData,
	StrapiCustomUpdateVendorResponse,
	StrapiCustomUpdateVendorInput,
	StrapiCustomUpdateVendorResponseData,
	USPSGetAddressResponse,
	USPSGetAddressInput,
	USPSGetAddressResponseData,
	USPSGetUSPSTokenResponse,
	USPSGetUSPSTokenInput,
	USPSGetUSPSTokenResponseData,
	VendureAdminCustomActiveAdministratorResponse,
	VendureAdminCustomActiveAdministratorResponseData,
	VendureAdminCustomAddFulfillmentToOrderResponse,
	VendureAdminCustomAddFulfillmentToOrderInput,
	VendureAdminCustomAddFulfillmentToOrderResponseData,
	VendureAdminCustomAdminLoginResponse,
	VendureAdminCustomAdminLoginInput,
	VendureAdminCustomAdminLoginResponseData,
	VendureAdminCustomCreateFacetValueResponse,
	VendureAdminCustomCreateFacetValueInput,
	VendureAdminCustomCreateFacetValueResponseData,
	VendureAdminCustomCreateProductResponse,
	VendureAdminCustomCreateProductInput,
	VendureAdminCustomCreateProductResponseData,
	VendureAdminCustomCreateProductVariantResponse,
	VendureAdminCustomCreateProductVariantInput,
	VendureAdminCustomCreateProductVariantResponseData,
	VendureAdminCustomGetActiveFacetsInChannelResponse,
	VendureAdminCustomGetActiveFacetsInChannelResponseData,
	VendureAdminCustomGetAllOrdersResponse,
	VendureAdminCustomGetAllOrdersInput,
	VendureAdminCustomGetAllOrdersResponseData,
	VendureAdminCustomGetFacetsResponse,
	VendureAdminCustomGetFacetsResponseData,
	VendureAdminCustomGetOrderByIdResponse,
	VendureAdminCustomGetOrderByIdInput,
	VendureAdminCustomGetOrderByIdResponseData,
	VendureAdminCustomGetOrderCountResponse,
	VendureAdminCustomGetOrderCountInput,
	VendureAdminCustomGetOrderCountResponseData,
	VendureAdminCustomProductVariantsResponse,
	VendureAdminCustomProductVariantsInput,
	VendureAdminCustomProductVariantsResponseData,
	VendureAdminCustomProductsResponse,
	VendureAdminCustomProductsResponseData,
	VendureAdminCustomSearchProductsResponse,
	VendureAdminCustomSearchProductsInput,
	VendureAdminCustomSearchProductsResponseData,
	VendureAdminCustomSetOrderTotalWeightResponse,
	VendureAdminCustomSetOrderTotalWeightInput,
	VendureAdminCustomSetOrderTotalWeightResponseData,
	VendureAdminCustomTransitionFulfillmentToStateResponse,
	VendureAdminCustomTransitionFulfillmentToStateInput,
	VendureAdminCustomTransitionFulfillmentToStateResponseData,
	VendureAdminCustomUpdateActiveAdministratorResponse,
	VendureAdminCustomUpdateActiveAdministratorInput,
	VendureAdminCustomUpdateActiveAdministratorResponseData,
	VendureAdminCustomUpdateProductOrderLimitsResponse,
	VendureAdminCustomUpdateProductOrderLimitsInput,
	VendureAdminCustomUpdateProductOrderLimitsResponseData,
	VendureAdminCustomUpdateVariantStockResponse,
	VendureAdminCustomUpdateVariantStockInput,
	VendureAdminCustomUpdateVariantStockResponseData,
	VendureShopCustomActiveCustomerResponse,
	VendureShopCustomActiveCustomerResponseData,
	VendureShopCustomAddToOrderResponse,
	VendureShopCustomAddToOrderInput,
	VendureShopCustomAddToOrderResponseData,
	VendureShopCustomAdjustOrderResponse,
	VendureShopCustomAdjustOrderInput,
	VendureShopCustomAdjustOrderResponseData,
	VendureShopCustomAdjustOrderLineResponse,
	VendureShopCustomAdjustOrderLineInput,
	VendureShopCustomAdjustOrderLineResponseData,
	VendureShopCustomCreateCustomerAddressResponse,
	VendureShopCustomCreateCustomerAddressInput,
	VendureShopCustomCreateCustomerAddressResponseData,
	VendureShopCustomCreateStripePaymentIntentResponse,
	VendureShopCustomCreateStripePaymentIntentResponseData,
	VendureShopCustomCustomerLoginResponse,
	VendureShopCustomCustomerLoginInput,
	VendureShopCustomCustomerLoginResponseData,
	VendureShopCustomGetActiveFacetsInChannelResponse,
	VendureShopCustomGetActiveFacetsInChannelResponseData,
	VendureShopCustomGetActiveOrderResponse,
	VendureShopCustomGetActiveOrderResponseData,
	VendureShopCustomGetAllOrdersResponse,
	VendureShopCustomGetAllOrdersInput,
	VendureShopCustomGetAllOrdersResponseData,
	VendureShopCustomGetFacetsResponse,
	VendureShopCustomGetFacetsResponseData,
	VendureShopCustomGetOrderByCodeResponse,
	VendureShopCustomGetOrderByCodeInput,
	VendureShopCustomGetOrderByCodeResponseData,
	VendureShopCustomGetPaymentMethodsResponse,
	VendureShopCustomGetPaymentMethodsResponseData,
	VendureShopCustomGetProductsResponse,
	VendureShopCustomGetProductsResponseData,
	VendureShopCustomGetShippingMethodsResponse,
	VendureShopCustomGetShippingMethodsResponseData,
	VendureShopCustomRemoveFromOrderResponse,
	VendureShopCustomRemoveFromOrderInput,
	VendureShopCustomRemoveFromOrderResponseData,
	VendureShopCustomSearchProductsResponse,
	VendureShopCustomSearchProductsInput,
	VendureShopCustomSearchProductsResponseData,
	VendureShopCustomSetOrderBillingAddressResponse,
	VendureShopCustomSetOrderBillingAddressInput,
	VendureShopCustomSetOrderBillingAddressResponseData,
	VendureShopCustomSetOrderShippingAddressResponse,
	VendureShopCustomSetOrderShippingAddressInput,
	VendureShopCustomSetOrderShippingAddressResponseData,
	VendureShopCustomSetOrderShippingMethodResponse,
	VendureShopCustomSetOrderShippingMethodInput,
	VendureShopCustomSetOrderShippingMethodResponseData,
	VendureShopCustomUpdateCustomerResponse,
	VendureShopCustomUpdateCustomerInput,
	VendureShopCustomUpdateCustomerResponseData,
	VendureShopCustomUpdateCustomerAddressResponse,
	VendureShopCustomUpdateCustomerAddressInput,
	VendureShopCustomUpdateCustomerAddressResponseData,
	VendureShopCustomUpdateOrderCustomFieldsResponse,
	VendureShopCustomUpdateOrderCustomFieldsInput,
	VendureShopCustomUpdateOrderCustomFieldsResponseData,
} from "./models";
export type UserRole = "customerAccess" | "adminAccess";

export const WUNDERGRAPH_S3_ENABLED = false;
export const WUNDERGRAPH_AUTH_ENABLED = true;

export enum AuthProviderId {
	"keycloak" = "keycloak",
}

export interface AuthProvider {
	id: AuthProviderId;
	login: (redirectURI?: string) => void;
}

export const defaultClientConfig: ClientConfig = {
	applicationHash: "ea4301d3",
	baseURL: "https://api.leftovers.today",
	sdkVersion: "0.184.2",
};

export const operationMetadata: OperationMetadata = {
	"KrogerCustom/GetKrogerAccessToken": {
		requiresAuthentication: true,
	},
	"KrogerCustom/GetKrogerProducts": {
		requiresAuthentication: true,
	},
	"StrapiCustom/CreateOrderNotificationStatus": {
		requiresAuthentication: true,
	},
	"StrapiCustom/CreatePost": {
		requiresAuthentication: true,
	},
	"StrapiCustom/CreateProduct": {
		requiresAuthentication: true,
	},
	"StrapiCustom/CreateUserProfile": {
		requiresAuthentication: true,
	},
	"StrapiCustom/DeleteFile": {
		requiresAuthentication: true,
	},
	"StrapiCustom/GetOrderNotificationStatus": {
		requiresAuthentication: true,
	},
	"StrapiCustom/GetPosts": {
		requiresAuthentication: true,
	},
	"StrapiCustom/GetProducts": {
		requiresAuthentication: true,
	},
	"StrapiCustom/GetUserProfiles": {
		requiresAuthentication: true,
	},
	"StrapiCustom/GetVendorAdmins": {
		requiresAuthentication: false,
	},
	"StrapiCustom/GetVendorUserMap": {
		requiresAuthentication: false,
	},
	"StrapiCustom/GetVendors": {
		requiresAuthentication: true,
	},
	"StrapiCustom/SingleImageUpload": {
		requiresAuthentication: true,
	},
	"StrapiCustom/UpdateOrderNotificationStatus": {
		requiresAuthentication: true,
	},
	"StrapiCustom/UpdateUserProfile": {
		requiresAuthentication: true,
	},
	"StrapiCustom/UpdateVendor": {
		requiresAuthentication: true,
	},
	"USPS/GetAddress": {
		requiresAuthentication: true,
	},
	"USPS/GetUSPSToken": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/ActiveAdministrator": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/AddFulfillmentToOrder": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/AdminLogin": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/CreateFacetValue": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/CreateProduct": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/CreateProductVariant": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/GetActiveFacetsInChannel": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/GetAllOrders": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/GetFacets": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/GetOrderById": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/GetOrderCount": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/ProductVariants": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/Products": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/SearchProducts": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/SetOrderTotalWeight": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/TransitionFulfillmentToState": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/UpdateActiveAdministrator": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/UpdateProductOrderLimits": {
		requiresAuthentication: true,
	},
	"VendureAdminCustom/UpdateVariantStock": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/ActiveCustomer": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/AddToOrder": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/AdjustOrder": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/AdjustOrderLine": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/CreateCustomerAddress": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/CreateStripePaymentIntent": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/CustomerLogin": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/GetActiveFacetsInChannel": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/GetActiveOrder": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/GetAllOrders": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/GetFacets": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/GetOrderByCode": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/GetPaymentMethods": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/GetProducts": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/GetShippingMethods": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/RemoveFromOrder": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/SearchProducts": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/SetOrderBillingAddress": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/SetOrderShippingAddress": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/SetOrderShippingMethod": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/UpdateCustomer": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/UpdateCustomerAddress": {
		requiresAuthentication: true,
	},
	"VendureShopCustom/UpdateOrderCustomFields": {
		requiresAuthentication: true,
	},
};

export type PublicUser = User<UserRole, PublicCustomClaims>;

export class WunderGraphClient extends Client {
	query<
		OperationName extends Extract<keyof Operations["queries"], string>,
		Input extends Operations["queries"][OperationName]["input"] = Operations["queries"][OperationName]["input"],
		Response extends Operations["queries"][OperationName]["response"] = Operations["queries"][OperationName]["response"]
	>(options: OperationName extends string ? QueryRequestOptions<OperationName, Input> : OperationRequestOptions) {
		return super.query<OperationRequestOptions, Response["data"], Response["error"]>(options);
	}

	mutate<
		OperationName extends Extract<keyof Operations["mutations"], string>,
		Input extends Operations["mutations"][OperationName]["input"] = Operations["mutations"][OperationName]["input"],
		Response extends Operations["mutations"][OperationName]["response"] = Operations["mutations"][OperationName]["response"]
	>(options: OperationName extends string ? MutationRequestOptions<OperationName, Input> : OperationRequestOptions) {
		return super.mutate<OperationRequestOptions, Response["data"], Response["error"]>(options);
	}

	subscribe<
		OperationName extends Extract<keyof Operations["subscriptions"], string>,
		Input extends Operations["subscriptions"][OperationName]["input"] = Operations["subscriptions"][OperationName]["input"],
		Response extends Operations["subscriptions"][OperationName]["response"] = Operations["subscriptions"][OperationName]["response"]
	>(
		options: OperationName extends string
			? SubscriptionRequestOptions<OperationName, Input>
			: SubscriptionRequestOptions,
		cb?: SubscriptionEventHandler<Response["data"], Response["error"]>
	) {
		return super.subscribe<OperationRequestOptions, Response["data"], Response["error"]>(options, cb);
	}
	public login(authProviderID: Operations["authProvider"], redirectURI?: string) {
		return super.login(authProviderID, redirectURI);
	}
	public async fetchUser<TUser extends PublicUser = PublicUser>(options?: FetchUserRequestOptions) {
		return super.fetchUser<TUser>(options);
	}
	public withHeaders = (headers: { [key: string]: string }) => {
		return new WunderGraphClient({
			...this.options,
			extraHeaders: headers,
		});
	};
}

export const createClient = (config?: CreateClientConfig) => {
	return new WunderGraphClient({
		...defaultClientConfig,
		...config,
		operationMetadata,
		csrfEnabled: true,
	});
};

export type Queries = {
	"KrogerCustom/GetKrogerProducts": {
		input: KrogerCustomGetKrogerProductsInput;
		response: { data?: KrogerCustomGetKrogerProductsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/GetOrderNotificationStatus": {
		input: StrapiCustomGetOrderNotificationStatusInput;
		response: { data?: StrapiCustomGetOrderNotificationStatusResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/GetPosts": {
		input?: undefined;
		response: { data?: StrapiCustomGetPostsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/GetProducts": {
		input: StrapiCustomGetProductsInput;
		response: { data?: StrapiCustomGetProductsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/GetUserProfiles": {
		input: StrapiCustomGetUserProfilesInput;
		response: { data?: StrapiCustomGetUserProfilesResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/GetVendorAdmins": {
		input: StrapiCustomGetVendorAdminsInput;
		response: { data?: StrapiCustomGetVendorAdminsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: false;
	};
	"StrapiCustom/GetVendorUserMap": {
		input: StrapiCustomGetVendorUserMapInput;
		response: { data?: StrapiCustomGetVendorUserMapResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: false;
	};
	"StrapiCustom/GetVendors": {
		input: StrapiCustomGetVendorsInput;
		response: { data?: StrapiCustomGetVendorsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"USPS/GetAddress": {
		input: USPSGetAddressInput;
		response: { data?: USPSGetAddressResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/ActiveAdministrator": {
		input?: undefined;
		response: { data?: VendureAdminCustomActiveAdministratorResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/GetActiveFacetsInChannel": {
		input?: undefined;
		response: { data?: VendureAdminCustomGetActiveFacetsInChannelResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/GetAllOrders": {
		input: VendureAdminCustomGetAllOrdersInput;
		response: { data?: VendureAdminCustomGetAllOrdersResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
		liveQuery: boolean;
	};
	"VendureAdminCustom/GetFacets": {
		input?: undefined;
		response: { data?: VendureAdminCustomGetFacetsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/GetOrderById": {
		input: VendureAdminCustomGetOrderByIdInput;
		response: { data?: VendureAdminCustomGetOrderByIdResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
		liveQuery: boolean;
	};
	"VendureAdminCustom/GetOrderCount": {
		input: VendureAdminCustomGetOrderCountInput;
		response: { data?: VendureAdminCustomGetOrderCountResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/ProductVariants": {
		input: VendureAdminCustomProductVariantsInput;
		response: { data?: VendureAdminCustomProductVariantsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/Products": {
		input?: undefined;
		response: { data?: VendureAdminCustomProductsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/SearchProducts": {
		input: VendureAdminCustomSearchProductsInput;
		response: { data?: VendureAdminCustomSearchProductsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/ActiveCustomer": {
		input?: undefined;
		response: { data?: VendureShopCustomActiveCustomerResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetActiveFacetsInChannel": {
		input?: undefined;
		response: { data?: VendureShopCustomGetActiveFacetsInChannelResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetActiveOrder": {
		input?: undefined;
		response: { data?: VendureShopCustomGetActiveOrderResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
		liveQuery: boolean;
	};
	"VendureShopCustom/GetAllOrders": {
		input: VendureShopCustomGetAllOrdersInput;
		response: { data?: VendureShopCustomGetAllOrdersResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetFacets": {
		input?: undefined;
		response: { data?: VendureShopCustomGetFacetsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetOrderByCode": {
		input: VendureShopCustomGetOrderByCodeInput;
		response: { data?: VendureShopCustomGetOrderByCodeResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
		liveQuery: boolean;
	};
	"VendureShopCustom/GetPaymentMethods": {
		input?: undefined;
		response: { data?: VendureShopCustomGetPaymentMethodsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetProducts": {
		input?: undefined;
		response: { data?: VendureShopCustomGetProductsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetShippingMethods": {
		input?: undefined;
		response: { data?: VendureShopCustomGetShippingMethodsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/SearchProducts": {
		input: VendureShopCustomSearchProductsInput;
		response: { data?: VendureShopCustomSearchProductsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
};

export type Mutations = {
	"KrogerCustom/GetKrogerAccessToken": {
		input?: undefined;
		response: { data?: KrogerCustomGetKrogerAccessTokenResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/CreateOrderNotificationStatus": {
		input: StrapiCustomCreateOrderNotificationStatusInput;
		response: { data?: StrapiCustomCreateOrderNotificationStatusResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/CreatePost": {
		input: StrapiCustomCreatePostInput;
		response: { data?: StrapiCustomCreatePostResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/CreateProduct": {
		input: StrapiCustomCreateProductInput;
		response: { data?: StrapiCustomCreateProductResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/CreateUserProfile": {
		input: StrapiCustomCreateUserProfileInput;
		response: { data?: StrapiCustomCreateUserProfileResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/DeleteFile": {
		input: StrapiCustomDeleteFileInput;
		response: { data?: StrapiCustomDeleteFileResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/SingleImageUpload": {
		input: StrapiCustomSingleImageUploadInput;
		response: { data?: StrapiCustomSingleImageUploadResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/UpdateOrderNotificationStatus": {
		input: StrapiCustomUpdateOrderNotificationStatusInput;
		response: { data?: StrapiCustomUpdateOrderNotificationStatusResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/UpdateUserProfile": {
		input: StrapiCustomUpdateUserProfileInput;
		response: { data?: StrapiCustomUpdateUserProfileResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"StrapiCustom/UpdateVendor": {
		input: StrapiCustomUpdateVendorInput;
		response: { data?: StrapiCustomUpdateVendorResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"USPS/GetUSPSToken": {
		input: USPSGetUSPSTokenInput;
		response: { data?: USPSGetUSPSTokenResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/AddFulfillmentToOrder": {
		input: VendureAdminCustomAddFulfillmentToOrderInput;
		response: { data?: VendureAdminCustomAddFulfillmentToOrderResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/AdminLogin": {
		input: VendureAdminCustomAdminLoginInput;
		response: { data?: VendureAdminCustomAdminLoginResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/CreateFacetValue": {
		input: VendureAdminCustomCreateFacetValueInput;
		response: { data?: VendureAdminCustomCreateFacetValueResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/CreateProduct": {
		input: VendureAdminCustomCreateProductInput;
		response: { data?: VendureAdminCustomCreateProductResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/CreateProductVariant": {
		input: VendureAdminCustomCreateProductVariantInput;
		response: { data?: VendureAdminCustomCreateProductVariantResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/SetOrderTotalWeight": {
		input: VendureAdminCustomSetOrderTotalWeightInput;
		response: { data?: VendureAdminCustomSetOrderTotalWeightResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/TransitionFulfillmentToState": {
		input: VendureAdminCustomTransitionFulfillmentToStateInput;
		response: { data?: VendureAdminCustomTransitionFulfillmentToStateResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/UpdateActiveAdministrator": {
		input: VendureAdminCustomUpdateActiveAdministratorInput;
		response: { data?: VendureAdminCustomUpdateActiveAdministratorResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/UpdateProductOrderLimits": {
		input: VendureAdminCustomUpdateProductOrderLimitsInput;
		response: { data?: VendureAdminCustomUpdateProductOrderLimitsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureAdminCustom/UpdateVariantStock": {
		input: VendureAdminCustomUpdateVariantStockInput;
		response: { data?: VendureAdminCustomUpdateVariantStockResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/AddToOrder": {
		input: VendureShopCustomAddToOrderInput;
		response: { data?: VendureShopCustomAddToOrderResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/AdjustOrder": {
		input: VendureShopCustomAdjustOrderInput;
		response: { data?: VendureShopCustomAdjustOrderResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/AdjustOrderLine": {
		input: VendureShopCustomAdjustOrderLineInput;
		response: { data?: VendureShopCustomAdjustOrderLineResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/CreateCustomerAddress": {
		input: VendureShopCustomCreateCustomerAddressInput;
		response: { data?: VendureShopCustomCreateCustomerAddressResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/CreateStripePaymentIntent": {
		input?: undefined;
		response: { data?: VendureShopCustomCreateStripePaymentIntentResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/CustomerLogin": {
		input: VendureShopCustomCustomerLoginInput;
		response: { data?: VendureShopCustomCustomerLoginResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/RemoveFromOrder": {
		input: VendureShopCustomRemoveFromOrderInput;
		response: { data?: VendureShopCustomRemoveFromOrderResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/SetOrderBillingAddress": {
		input: VendureShopCustomSetOrderBillingAddressInput;
		response: { data?: VendureShopCustomSetOrderBillingAddressResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/SetOrderShippingAddress": {
		input: VendureShopCustomSetOrderShippingAddressInput;
		response: { data?: VendureShopCustomSetOrderShippingAddressResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/SetOrderShippingMethod": {
		input: VendureShopCustomSetOrderShippingMethodInput;
		response: { data?: VendureShopCustomSetOrderShippingMethodResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/UpdateCustomer": {
		input: VendureShopCustomUpdateCustomerInput;
		response: { data?: VendureShopCustomUpdateCustomerResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/UpdateCustomerAddress": {
		input: VendureShopCustomUpdateCustomerAddressInput;
		response: { data?: VendureShopCustomUpdateCustomerAddressResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
	"VendureShopCustom/UpdateOrderCustomFields": {
		input: VendureShopCustomUpdateOrderCustomFieldsInput;
		response: { data?: VendureShopCustomUpdateOrderCustomFieldsResponse["data"]; error?: ClientOperationErrors };
		requiresAuthentication: true;
	};
};

export type Subscriptions = {
	"VendureAdminCustom/GetAllOrders": {
		input: VendureAdminCustomGetAllOrdersInput;
		response: { data?: VendureAdminCustomGetAllOrdersResponse["data"]; error?: ClientOperationErrors };
		liveQuery: true;
		requiresAuthentication: true;
	};
	"VendureAdminCustom/GetOrderById": {
		input: VendureAdminCustomGetOrderByIdInput;
		response: { data?: VendureAdminCustomGetOrderByIdResponse["data"]; error?: ClientOperationErrors };
		liveQuery: true;
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetActiveOrder": {
		input?: undefined;
		response: { data?: VendureShopCustomGetActiveOrderResponse["data"]; error?: ClientOperationErrors };
		liveQuery: true;
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetOrderByCode": {
		input: VendureShopCustomGetOrderByCodeInput;
		response: { data?: VendureShopCustomGetOrderByCodeResponse["data"]; error?: ClientOperationErrors };
		liveQuery: true;
		requiresAuthentication: true;
	};
};

export type LiveQueries = {
	"VendureAdminCustom/GetAllOrders": {
		input: VendureAdminCustomGetAllOrdersInput;
		response: { data?: VendureAdminCustomGetAllOrdersResponse["data"]; error?: ClientOperationErrors };
		liveQuery: true;
		requiresAuthentication: true;
	};
	"VendureAdminCustom/GetOrderById": {
		input: VendureAdminCustomGetOrderByIdInput;
		response: { data?: VendureAdminCustomGetOrderByIdResponse["data"]; error?: ClientOperationErrors };
		liveQuery: true;
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetActiveOrder": {
		input?: undefined;
		response: { data?: VendureShopCustomGetActiveOrderResponse["data"]; error?: ClientOperationErrors };
		liveQuery: true;
		requiresAuthentication: true;
	};
	"VendureShopCustom/GetOrderByCode": {
		input: VendureShopCustomGetOrderByCodeInput;
		response: { data?: VendureShopCustomGetOrderByCodeResponse["data"]; error?: ClientOperationErrors };
		liveQuery: true;
		requiresAuthentication: true;
	};
};

export interface Operations
	extends OperationsDefinition<
		Queries,
		Mutations,
		Subscriptions,
		LiveQueries,
		UserRole,
		{},
		keyof typeof AuthProviderId
	> {}
