/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArchiveBoxArrowDownIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline';
import { useCounts, useNotifications } from '@novu/react/hooks';
import { useNavigate } from '@remix-run/react';
import moment from 'moment';
import React, { useEffect } from 'react';

export default function CustomNotifications({ notificationOpen, toggleNotificationOpenState, notificationCount, setNotificationCount }) {

  const { notifications, isLoading, fetchMore, hasMore, refetch, readAll, archiveAllRead } = useNotifications()
  const { counts: unreadCounts } = useCounts({ filters: [{ read: false }] })
  const { counts: readCounts } = useCounts({ filters: [{ read: true }] })

  const [cachedUnreadCount, setCachedUnreadCount] = React.useState<number>(notificationCount)
  // const [cachedArchivedCount, setCachedArchivedCount] = React.useState<number>(0)

  const navigate = useNavigate()

  // Useeffect to refetch notifications when unread or archived counts change, without this,
  // reading on a different device will not update the notification count on the current device
  // Both BellButton and CustomNotifications components can update notification count to keep in sync
  // Because iOS Safari will suspend the websocket after inactivity, by updating state we reload the 
  // component and re-establish the websocket connection
  useEffect(() => {
    if (unreadCounts && unreadCounts[0].count != cachedUnreadCount) {
      refetch()
      setCachedUnreadCount(unreadCounts[0].count)
    }
  }, [unreadCounts, cachedUnreadCount, refetch])

  // useEffect(() => {
  //   if (notificationOpen == false)
  //     // readAll()
  //     markAllNotificationsAsSeen()
  // }, [markAllNotificationsAsSeen, notificationOpen])

  return (
    <>
      {isLoading && getLoadingSkeleton()}
      {!isLoading && unreadCounts && unreadCounts[0].count != notificationCount ? setNotificationCount(unreadCounts[0].count) : ''}
      <div className='h-full min-w-80 max-w-80 bg-white rounded-bl-3xl overflow-y-auto'>
        {!isLoading && (
          <div className='flex flex-row justify-center place-items-center align-center pl-4'>
            <div className='flex justify-end py-3 pr-3'>
              <button className='py-1.5 px-2 text-gray-700 font-semibold text-xs bg-white hover:bg-gray-100 disabled:text-gray-300 disabled:bg-white rounded-md border'
                onClick={() => readAll()}
                disabled={unreadCounts && unreadCounts[0].count === 0}
              >
                <span className='flex place-items-center'><EnvelopeOpenIcon className='h-4 w-4' /><p className='pl-1'>Mark all as Read</p></span>
              </button>
            </div>
            <div className='flex justify-end py-3 pr-3'>
              <button className='py-1.5 px-2 text-gray-700 font-semibold text-xs bg-white hover:bg-gray-100 disabled:text-gray-300 disabled:bg-white rounded-md border'
                onClick={() => { archiveAllRead() }}
                disabled={notifications?.length === 0 || (notifications?.length == (unreadCounts && unreadCounts[0].count))}
              >
                <span className='flex place-items-center'><ArchiveBoxArrowDownIcon className='h-4 w-4' /><p className='pl-1'>Archive all Read</p></span>
              </button>
            </div>
          </div>
        )}

        {!isLoading && notifications && notifications.map(notification => (
          <div className="pointer-events-auto w-full max-w-sm bg-white border-b-0 justify-start text-left"
            key={notification.id + notification.isRead.toString() + notification.isArchived.toString()}
            onClick={() => (
              notification.read(),
              notification.redirect?.url ? navigate(new URL(notification.redirect.url).pathname) : '',
              toggleNotificationOpenState(),
              refetch())}
          >
            <div className="relative p-4">
              <div className='absolute top-0 right-3'>
                {!notification.isRead && <span className='text-warmTerracota-700 text-5xl'>&#8226;</span>}
              </div>
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    alt=""
                    src={notification.avatar}
                    className="h-10 w-10 rounded-full"
                  />
                </div>
                <button className="ml-3 w-0 flex-1 text-left">
                  <p className="text-sm font-medium text-gray-900">{notification.subject}</p>
                  <p className="mt-1 text-sm text-gray-500">{notification.body}</p>
                  <p className="mt-1 text-xs text-gray-400">
                    {moment(notification.createdAt).fromNow()}
                  </p>
                  {/* <div className="mt-4 flex">
                      {notification.primaryAction && (
                        <button
                          type="button"
                          className="inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          {notification.primaryAction?.label}
                        </button>)}

                      {notification.secondaryAction && (
                        <button
                          type="button"
                          className="ml-3 inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          {notification.secondaryAction?.label}
                        </button>)}

                    </div> */}
                </button>
              </div>
            </div>
          </div>
        ))}
        {!isLoading && hasMore && (
          <div className='flex justify-center py-5'>
            <button className='py-1.5 px-2 bg-warmTerracota-700 text-sm text-white font-semibold rounded-md' onClick={fetchMore}>Load More</button>
          </div>)}
      </div>
    </>
  )
}

function getLoadingSkeleton() {
  return (
    <div role="status" className="pt-20 max-w-md p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-300 md:p-6 dark:border-gray-200">
      <div className="flex items-center justify-between">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-200"></div>
        </div>
        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-200 w-12"></div>
      </div>

    </div>
  )
}