import React from 'react'
import HashtagLogo from '../../assets/platform_logos/HashtagLogo.png'
import SeattleSkyline from '../../assets/platform_logos/SeattleSkyline.png'

const navigation = {
 main: [
  { name: 'About', href: '#' },
  { name: 'Blog', href: '#' },
  { name: 'Terms and Conditions', href: '#' },
  { name: 'Privacy Policy', href: '#' },
 ],
 social: [
  {
   name: 'Facebook',
   href: '#',
   icon: (props) => (
    <svg
     xmlns="http://www.w3.org/2000/svg"
     className="h-5 w-5"
     fill="currentColor"
     viewBox="0 0 24 24"
    >
     <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
    </svg>
   ),
  },
  {
   name: 'Instagram',
   href: '#',
   icon: (props) => (
    <svg
     xmlns="http://www.w3.org/2000/svg"
     className="h-5 w-5"
     fill="currentColor"
     viewBox="0 0 24 24"
    >
     <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
    </svg>
   ),
  },
  {
   name: 'TikTok',
   href: '#',
   icon: (props) => (
    <svg
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 448 512"
     className="h-5 w-5"
    >
     {/* <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
     <path
      fill="currentColor"
      d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
     />
    </svg>
   ),
  },
  {
   name: 'Pinterest',
   href: '#',
   icon: (props) => (
    <svg
     xmlns="http://www.w3.org/2000/svg"
     className="h-5 w-5"
     fill="currentColor"
     viewBox="0 0 24 24"
    >
     <path
      d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
      fillRule="evenodd"
      clipRule="evenodd"
     />
    </svg>
   ),
  },
  {
   name: 'LinkedIn',
   href: '#',
   icon: (props) => (
    <svg
     xmlns="http://www.w3.org/2000/svg"
     className="h-5 w-5"
     fill="currentColor"
     viewBox="0 0 24 24"
    >
     <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
    </svg>
   ),
  },
 ],
}

export default function Footer() {
 return (
  <footer className="bg-white">
   <div className="mx-auto max-w-7xl overflow-hidden px-6 py-6">
    {/* <div>
          <nav
            className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
            aria-label="Footer"
          >
            {navigation.main.map((item) => (
              <div key={item.name} className="pb-6">
                <a
                  href={item.href}
                  className="text-sm leading-6 text-gray-600 hover:text-gray-900"
                >
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
        </div> */}
    <div>
     {/* <div className="mt-5 flex justify-center space-x-10">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <img
            src={HashtagLogo}
            className="mx-auto mt-5 flex max-w-32 justify-center"
          /> */}
     <img
      src={SeattleSkyline}
      className="mx-auto flex max-w-32 justify-center"
     />
     <p className="text-center text-xs leading-5 text-gray-500">
      &copy; 2025 Leftovers.today, Inc. All rights reserved.
     </p>
    </div>
   </div>
  </footer>
 )
}
